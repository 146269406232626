import React from "react"
import H2 from "./Utility/heading2"
import Button from "./Utility/button"

export default props => (
    <div class="about" style={{ backgroundImage: `url(${props.imgSrc})` }} >
        <div class="media">
            <img src={props.imgSrc} alt="" />
        </div>
        <div class="content" style={{ backgroundColor: props.aboutBGColor, color: props.bodyColor }}>
            <H2 headingText1={props.headingText1} headingText2={props.headingText2} headingColor1={props.headingColor1} headingColor2={props.headingColor2} />
            <p class="about-body">{props.bodyText}</p>
            <div class="about-detail">
                <img src={props.detail1Img} alt="" />
                <div>
                    <h4>{props.detail1Heading}</h4>
                    <p>{props.detail1Body}</p>
                </div>
            </div>
            <div class="about-detail">
                <img src={props.detail2Img} alt="" />
                <div>
                    <h4>{props.detail2Heading}</h4>
                    <p>{props.detail2Body}</p>
                </div>
            </div>
            {
                props.buttonText && (<Button buttonLink={props.buttonLink} buttonText={props.buttonText} buttonColor={props.buttonColor} buttonBGColor={props.buttonBGColor}/>)
            }
            {
                props.footnoteText && (<p class="note" style={{ color: props.footnoteColor }}>{props.footnoteText}</p>)
            }
        </div>
    </div>
)