import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import Hero from "../components/hero"
import CTA from "../components/cta"
import OurStory from "../components/our-story"
import CenteredCTA from "../components/cta--centered"
import SocialCTA from "../components/cta--social"
import { Row } from "reactstrap"

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Protein & Meat Alternatives</title>
          <meta
            name="description"
            content="Eat foods that are as healthy as they are delicious. Raised & Rooted&trade; makes it easy to incorporate plant based protein and blended meat alternatives in your diet."
          />
        </Helmet>
        <Layout>
          <Hero
            heroDirection="reverse"
            bgImage="/paper-background.jpg"
            eyebrowText="Vegetarian? Flexitarian? Carnivore?"
            eyebrowColor="#363636"
            headingText1="Plant-Based Protein & Meat Alternatives"
            headingColor1="#fafafa"
            subheadingText1="WE'VE SPICED UP"
            subheadingColor1="#006B2D"
            subheadingText2="YOUR FAVORITE FOODS WITH PLANTS"
            subheadingColor2="#006B2D"
            bodyColor="#363636"
            bodyText="And they taste oh-so-satisfying. Our new lineup of meat alternatives is packed with all the crispy, juicy, hot off the grill flavors and textures you love. 
            These aren’t just the first plant protein products you’ll actually crave. These are plants made meatier. "
            buttonLink="/products/"
            buttonText="Hungry yet?"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
          >
            <img src="/RaisedRooted-Homepage-HeaderImage-PlatedNuggets-1050x1255.png" alt="" />
            <img src="/RaisedRooted-Homepage-HeaderImage-BuffaloChickenSand-1050x1255.png" alt="" />
          </Hero>
          <CTA
            idName="Give Me That"
            imgSrc="/raised-and-rooted-plant-based-products-lineup-625x417.png"
            altText="Raised And Rooted Plant Based Products Lineup"
            headingText1="All Your Favorite Foods"
            headingText2="That just happen to be made with plants"
            headingColor1="#006B2D"
            headingColor2="#006B2D"
            bodyColor=""
            bodyText="We’re revolutionizing what plants can do. And it turns out, they can taste better than you ever imagined. Check out our new alternative protein product lineup that harnesses all your favorite flavors—from only lean, clean plant protein."
            buttonText="Give Me That"
            buttonLink="/products"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
          />
          {/* <CTA
            ctaBGColor="#97D700"
            imgSrc="/Product_Burgers_Package_PGP.jpg"
            eyebrowText=""
            eyebrowColor="#006B2D"
            headingText1="Patties Blended"
            headingText2="with Beef & Plant Protein!"
            headingColor1="#006B2D"
            headingColor2="#006B2D"
            bodyColor="#006B2D"
            bodyText="There’s a tasty new guest coming to grills and gatherings everywhere. It’s Raised & Rooted&trade; Patties blended with Angus beef and plant protein. With 19 grams of protein per serving, fewer calories*, less saturated fat* but with all the juicy taste"
            itemColor="#006B2D"
            slideReviewID="blended-patties"
            initialSlide={0}
            valueText1="40"
            unitText1="%"
            labelText1="Less Calories*"
            valueText2="19"
            unitText2="g"
            labelText2="Protein"
            valueText3="60"
            unitText3="%"
            labelText3="Less Saturated Fat*"
            buttonText="View Product"
            buttonLink="products/blended-patties/"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
            footnoteText="*Compared to 80% lean beef, 20% fat burger"
            footnoteColor="#006B2D"
          /> */}
          <OurStory
            aboutBGColor="#006B2D"
            imgSrc="/RaisedRooted-Homepage-DontChangeImage-GroundBeefTacos-1440x1010.png"
            headingText1="Don't Change Who You Are"
            headingText2="to Improve How You Eat"
            headingColor1="#A1DED2"
            bodyColor="#FFFFFF"
            bodyText="Everyone deserves to eat well. That’s why we’re revolutionizing what plants can do in delicious and nutritious ways."
            detail1Img="/pea.svg"
            detail1Heading="Nutrition"
            detail1Body="We’ve harnessed the power of plants to make mouthwatering meat alternatives with plant based protein."
            detail2Img="/taste.svg"
            detail2Heading="Taste"
            detail2Body="Don’t believe our plant based products can taste crazy good and be nutritious? Try us. Our delicious and diverse plant based high-protein foods will combine your favorite familiar flavors with even better nutrition."
            buttonText="About Us"
            buttonLink="/about-us/"
            buttonColor="#006B2D"
            buttonBGColor="#A1DED2"
          />
          <Row className="no-gutters mb-5">
            <CenteredCTA
              twoColumn
              bgImage="/illustrated-background_news.svg"
              badgeSrc="/news.svg"
              headingText2="News Nuggets"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="We're pretty game-changing, but don't just take our word for it."
              buttonText="Read Our News"
              buttonLink="/about-us#news"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
            <CenteredCTA
              twoColumn
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </Row>
          <SocialCTA
            imgSrc="/RaisedRooted-Homepage-GiveUsAFollowImage-BuffaloChickenSand-625x417.png"
            headingText1="Give us a"
            headingText2="Follow!"
            headingColor1="#006B2D"
            bodyColor="#006B2D"
            bodyText="Check us out on social media. Warning: You might get hungry."
          />
          <CenteredCTA
            bgImage="/illustrated-background.svg"
            badgeSrc="/default.svg"
            headingText2="Questions & Answers"
            headingColor2="#006B2D"
            bodyColor="#006B2D"
            bodyText="What’s in Raised & Rooted&trade; products? How are they made? Are they nutritious? How do they taste? You’ve got questions, we’ve got answers."
            buttonText="Read Our FAQs"
            buttonLink="/faqs/"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
          />
        </Layout>
      </React.Fragment>
    )
  }
}
